<template>
    <div class="container">
    <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
      <p class="col-md-4 mb-0 text-muted"><sm>&copy; Exceltour Limited, a company registered in England & Wales no. 09403130.</sm></p>
  
      <a href="/" class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
        <img src="@/assets/img/logo-only.svg" style="height:3rem;" />
      </a>
  
      <ul class="nav col-md-4 justify-content-end">
        <li class="nav-item"><a href="/" class="nav-link px-2 text-muted">{{ $t("buttons.home") }}</a></li>
        <li class="nav-item"><a href="#contact" class="nav-link px-2 text-muted">{{ $t("buttons.contact") }}</a></li>
      </ul>
    </footer>
  </div>
</template>
<script type="text/javascript">
/* eslint-disable */
export default{    
    name: 'Footer'
}
</script>